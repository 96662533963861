import TestiAvatar from '../../assets/avatar.jpg';
export const responsive = {
    widedesktop: {
        breakpoint: { max: 3000, min: 1600 },
        items: 4,
        partialVisibilityGutter: 40 // this is needed to tell the amount of px that should be visible.
    },
    desktop: {
        breakpoint: { max: 1600, min: 1024 },
        items: 2,
        partialVisibilityGutter: 40 // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
        breakpoint: { max: 1024, min: 767 },
        items: 1,
        partialVisibilityGutter: 1 // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1,
        partialVisibilityGutter: 10 // this is needed to tell the amount of px that should be visible.
    }
}

export const testimonials = [
    {
        name: "Roopam",
        age: "26",
        desgnation: "Found flatmate in Indiranagar",
        review: "Flatshare is more than just an app; it's a way to find a new family in a new city. So grateful for the smooth experience and amazing matches!",
        avatar: TestiAvatar,
        colorCode: 1,
    },
    {
        name: "Rahul",
        age: "20",
        desgnation: "Found flatmate in Indiranagar",
        review: "Incredible app! Made finding flat & flatmates quick and hassle-free. The user interface is sleek and easy to navigate. Highly recommend to anyone moving to a new city!",
        avatar: TestiAvatar,
        colorCode: 2,
    },
    {
        name: "Neha",
        age: "22",
        desgnation: "Found flatmate in Indiranagar",
        review: "Best rental housing app out there! I was a bit nervous about moving to a new city, but Flatshare made it so easy to find like-minded people. The filters are super helpful.",
        avatar: TestiAvatar,
        colorCode: 3,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "I was hesitant about living with flatmates until I discovered Flatshare. Now, I've found flatmates who feel like family. Flatshare truly helped better my life!",
        avatar: TestiAvatar,
        colorCode: 4,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "Flatshare's easy-to-use interface made finding flatmates a breeze. Now, I live with roommates (fellow foodies) who enjoy exploring the local food scene with me.",
        avatar: TestiAvatar,
        colorCode: 1,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "Flatshare's user-friendly platform made finding flatmates a breeze. Now, I live with flatmates who make coming home in the evening something I look forward to.",
        avatar: TestiAvatar,
        colorCode: 2,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "Moving to a new city can be daunting, but Flatshare made it easy. Flatshare turned strangers into lifelong friends, and I couldn't be happier with my living situation.",
        avatar: TestiAvatar,
        colorCode: 3,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "Flatshare helped me find my perfect. Living with them has been an absolute blast, and I'm grateful for the fun and laughter we share daily.",
        avatar: TestiAvatar,
        colorCode: 4,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "Flatshare's recommendations made it seamless for me to connect with flatmates who share my interests and values. Flatshare truly exceeded my expectations!",
        avatar: TestiAvatar,
        colorCode: 1,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "Flatshare made my move to Mumbai so much easier. I found flatmates who share my love for movies. Living with them feels like being at home.",
        avatar: TestiAvatar,
        colorCode: 2,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "Thanks to Flatshare, I found flatmates who love for gaming and late-night movie marathons. I'm grateful for the friendships we've formed.",
        avatar: TestiAvatar,
        colorCode: 3,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "Flatshare helped me find flatmates who share my enthusiasm for healthy living. It fosters a supportive living environment, and I couldn't be happier with my living situation.",
        avatar: TestiAvatar,
        colorCode: 4,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "Absolutely love Flatshare! It connected me with my current flatmates who have become great friends. The app is also very user-friendly and makes the search process fun.",
        avatar: TestiAvatar,
        colorCode: 1,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "Great concept, well executed. As someone who moves cities often for work, Flatshare has been a game changer for me. The community feature is a great touch.",
        avatar: TestiAvatar,
        colorCode: 2,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "A lifesaver for relocators! I found a perfect match within a week. The app is smooth. Can’t imagine moving without it.",
        avatar: TestiAvatar,
        colorCode: 3,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "Thanks to Flatshare, I found a fantastic flatmate and a new best friend. The app is not only functional but also beautifully designed. It’s clear a lot of thought went into this.",
        avatar: TestiAvatar,
        colorCode: 4,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "Just moved to a new city and Flatshare made the transition so much smoother. The app is straightforward and the match system works wonderfully. Highly recommended!",
        avatar: TestiAvatar,
        colorCode: 1,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "Found my perfect roommate in just days! The chat feature makes communicating so easy and safe. No need to share your numbers with random strangers.",
        avatar: TestiAvatar,
        colorCode: 2,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "I found a flatmate who turned out to be in the same profession as me! It’s amazing how well the app matches based on compatibility.",
        avatar: TestiAvatar,
        colorCode: 3,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "Love the design and user interface of the app. It's not just functional but also very pleasing to use.",
        avatar: TestiAvatar,
        colorCode: 4,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "Flatshare helped me transition smoothly to Delhi. The app is easy to use and the matches are accurately aligned with my preferences.",
        avatar: TestiAvatar,
        colorCode: 1,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "Finding a flatmate has never been easier. Flatshare's community features helped me find a flatmate that made me feel at home right away.",
        avatar: TestiAvatar,
        colorCode: 2,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "Flatshare is exactly what I needed moving to Pune. Found great flatmates and the app was super intuitive to use.",
        avatar: TestiAvatar,
        colorCode: 3,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "As a student, I found Flatshare incredibly useful. It connected me with other students and made finding the right place simple.",
        avatar: TestiAvatar,
        colorCode: 4,
    },
    {
        name: "Kaushal",
        age: "21",
        desgnation: "Found flatmate in Indiranagar",
        review: "I appreciate how the app allowed us to match based on mutual interests, which has led to a harmonious living situation.",
        avatar: TestiAvatar,
        colorCode: 2,
    }

]