import {relatedBlog} from './Blog/Blog.constants';
import Hero from '../components/hero/Hero';
import Map from '../components/map/Map';
import FAQ from '../components/faq/FAQ';
import HappySharing from '../components/happysharing/HappySharing';
import Testimonials from '../components/testimonial/Testimonials';
import Upnext from '../components/upnext/Upnext';
import SEO from './SEO';
const Home = () => {
    return (
        <>
            <SEO title='Home | FlatShare' />
            <Hero></Hero>
            <Map></Map>
            <Testimonials></Testimonials>
            <FAQ></FAQ>
            <HappySharing></HappySharing>
            <Upnext relatedBlog={relatedBlog} header={"Live your Best Life with <b>Flatshare Guides</b>"}/>

        </>
    )
}

export default Home;