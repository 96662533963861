import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { testimonials, responsive } from './Testimonial.constants';
import './Testimonials.css';
const Testimonials = () => {

    const createProps = () => {
        let centerMode = false, infinite = true;
        if(window.screen.width > 768) {
            centerMode = true;
        }

        return {centerMode, infinite, responsive, autoPlay: true, autoPlaySpeed: 3000};
    }

    return (
        <>
            <section className="container-fluid faq-container">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className='row'>
                                <div className="col-12">
                                    <h1 className='testi-section-header section-header-font'>Words From <span>Our Community</span></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonial-section">
                <Carousel {...createProps()}>
                    {testimonials.map((item, key) => (
                        <div key={key} className="single-testimonial-wrapper">
                            <div className={`col-12 single-testimonial-block testi-color-code-${item.colorCode}`}>
                                <div className="row">
                                    <div className="col-12 testi-text">
                                        <p>{item.review}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                </Carousel>
            </section>
        </>
    )
};

export default Testimonials;