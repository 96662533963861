import { blog2Content, relatedBlog } from './Blog.constants';
import { images } from './Blog.images';
import Upnext from '../../components/upnext/Upnext';
import SEO from '../SEO';
import './Blog2.css';
const Blog2 = () => {
    return (
        <>
            <SEO title='How To Find Flatmates & Roommates For Rent Near Me | FlatShare' />
            <section className='container-fluid topsec'>
                <div className='container'>
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-4 blog-2-top-banner-boxes text-center'>
                                <img src={images.b2_banner_1} className='b2_banner_1' />
                            </div>
                            <div className='col-4 blog-2-top-banner-boxes text-center'>
                                <img src={images.b2_banner_2} className='b2_banner_2' />
                                <img src={images.b2_banner_5} className='b2_banner_5 icons' />
                                <img src={images.b2_banner_6} className='b2_banner_6 icons' />
                                <img src={images.b2_banner_7} className='b2_banner_7 icons' />
                                <img src={images.b2_banner_8} className='b2_banner_8 icons' />
                            </div>
                            <div className='col-4 blog-2-top-banner-boxes text-center'>
                                <img src={images.b2_banner_3} className='b2_banner_3' />
                                <img src={images.b2_banner_4} className='b2_banner_4 icons' />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-11 col-12 blog-2-mid-banner'>
                        <h1>How To Find Flatmates & Roommates For Rent Near Me</h1>
                        <h6>Are you tired of <span className='text--App-Orange'>endless</span> online searches, <span className='text--Primary-primary-400'>awkward interviews</span>, and <span className='text--App-Pink'>frustrating roommate</span> mismatches?<br />Look no further! Welcome to our comprehensive guide on finding the <span className='text--App-Green'>perfect flatmates hassle-free</span>.</h6>
                    </div>
                </div>
            </section>
            {
                blog2Content.map(({ header, ul, content, buttons, mainClasses, numberOfTile, subHeading, tiles }, i) => (
                    <section className={`container-fluid blog-section blog-section-${i}`} key={i}>
                        <div className='container'>
                            <div className='row'>
                                <div className={mainClasses}>
                                    <h4>{header}</h4>
                                    {ul && <ul>
                                        {ul.map((li, i) => (
                                            <li key={i}>{li}</li>
                                        ))}
                                    </ul>}
                                    {subHeading && subHeading.map((h6, i) => (<h6>{h6}</h6>))}
                                    {content && content.map((p, i) => (<p key={i} className='pstyle'>{p}</p>))}
                                    {buttons && <div className='row'>
                                        <div className="col-lg-6 col-md-12 col-9 text-center store-links">
                                            {
                                                buttons.map(({ img, link }, i) => (
                                                    <a href={link} target='_blank'><img src={img} alt='' /></a>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    }
                                </div>
                                {tiles && <div className='col-12 section-tile-area'>
                                    <div className='row'>
                                        {
                                            tiles.map(({ img, title, body }, i) => (
                                                <div className={`col-md-${numberOfTile} col-12 white-box-tile-parent`} key={i}>
                                                    <div className='white-box-tile blog-2-tile-style'>
                                                        <img src={img} />
                                                        <h6>{title}</h6>
                                                        <p>{body}</p>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>}
                            </div>
                        </div>
                    </section>
                ))
            }
            <Upnext relatedBlog={relatedBlog} ids={[2]} />
        </>
    )
};

export default Blog2;