import React from "react";
import Carousel from "react-multi-carousel";
import SEO from '../SEO';
import { responsive, getCities, blog1Bottompart, relatedBlog } from './Blog.constants';
import "react-multi-carousel/lib/styles.css";
import AL from '../../assets/arrowL.svg'
import AR from '../../assets/arrowR.svg'
import Happy from '../../assets/happy.svg';
import Pros from '../../assets/pros.svg';
import Cons from '../../assets/cons.svg';
import Sad from '../../assets/sad.svg';
import Upnext from '../../components/upnext/Upnext';
import './Blog1.css';
const Blog1 = () => {
    const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        const { carouselState: { currentSlide, totalItems, slidesToShow } } = rest;
        console.log(rest);
        const last = (totalItems - slidesToShow) === currentSlide;
        return (
            <div className="carousel-button-group">
                <button className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()}><img src={AL} /></button>
                <button className={last ? 'disable' : ''} onClick={() => next()}><img src={AR} /></button>
            </div>
        );
    };
    return (
        <>
            <SEO title='Top Cities to Live in India | FlatShare' />
            <div className='container-fluid banner'>
                <div className='row'>
                    <div className='container-fluid banner-overlay'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12 banner-title-parent'>
                                    <div className='banner-title'>
                                        <div className='col-12'>
                                            <h1>Top Cities to Live in India</h1>
                                        </div>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <div className='col-xl-9 col-12'>
                                                    <p>India offers a plethora of <span className='text--App-Orange'>vibrant cities</span>, each with its unique <span className='text--Primary-primary-500'>charm</span> and <span className='text--App-Green '>opportunities</span>, making it a daunting task for students and young professionals to choose the perfect destination. </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='row'>
                                                <div className='col-xl-9 col-12'>
                                                    <p>Whether you're a student seeking the best college experience or a young professional looking to kickstart your career, <span className='text--App-Pink'>these top cities in India offer a blend of academic excellence, career prospects, and quality of life</span>.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                getCities().map(({ name, description, sliderSection, pros, cons, analytics }, key) => (
                    <>
                        <section className='container-fluid single-city-section' key={key}>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-md-8 col-12 city-desc'>
                                                <h3>{name}</h3>
                                                {description.map((e, i) => (
                                                    <p key={i}>{e}</p>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {
                            sliderSection.map(({ name, slides }, key) => (
                                <section className='container-fluid city-slider-section' key={key}>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <h4>{name}</h4>
                                            </div>
                                            <div className="col-12">
                                                <section className="slider-parent">
                                                    <Carousel additionalTransfrom={0}
                                                        arrows={false}
                                                        autoPlaySpeed={3000}
                                                        centerMode={false}
                                                        draggable
                                                        focusOnSelect={false}
                                                        infinite={false}
                                                        keyBoardControl
                                                        minimumTouchDrag={80}
                                                        pauseOnHover
                                                        renderArrowsWhenDisabled={false}
                                                        renderDotsOutside={false}
                                                        responsive={responsive}
                                                        rewind={false}
                                                        rewindWithAnimation={false}
                                                        rtl={false}
                                                        shouldResetAutoplay
                                                        showDots={false}
                                                        sliderClass=""
                                                        partialVisible={true}
                                                        swipeable renderButtonGroupOutside={true} customButtonGroup={<ButtonGroup />}>
                                                        {
                                                            slides.map(({ img, name }, key) => (
                                                                <div key={key} className="single-city-slider-block">
                                                                    <div className="slider-image-overlay"></div>
                                                                    <img src={img} />
                                                                    <p>{name}</p>
                                                                </div>
                                                            ))
                                                        }
                                                    </Carousel>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            ))
                        }
                        <section className="container-fluid city-stats-section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-8 offset-xl-0 offset-lg-2 offset-1 col-10 city-pros-cons">
                                        <div className="row">
                                            <div className="col-xl-6 col-12 city-pros-cons-container">
                                                <img src={Happy} className="happy-face" />
                                                <div className="pros-cons-cont pros">
                                                    <div className="header"><h5>Pro's</h5><span>•••</span></div>
                                                    <div className="body">
                                                        {
                                                            pros.map((e, i) => (
                                                                <p key={i}>{e}</p>
                                                            ))
                                                        }
                                                    </div>
                                                    <img src={Pros} className="right" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-12 city-pros-cons-container">
                                                <div className="pros-cons-cont cons">
                                                    <div className="header"><h5>Con’s</h5><span>•••</span></div>
                                                    <div className="body">
                                                        {
                                                            cons.map((e, i) => (
                                                                <p key={i}>{e}</p>
                                                            ))
                                                        }
                                                    </div>
                                                    <img src={Cons} className="wrong" />
                                                </div>
                                                <img src={Sad} className="sad-face" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 city-analytics">
                                        <div className="row justify-content-md-center">
                                            <div className="col-lg-10">
                                                <div className="analytics-container">
                                                    <div className="header">
                                                        <h3>City Score</h3>
                                                    </div>
                                                    <div className="body">
                                                        {
                                                            analytics.map(({ icon, bg, name, score }, i) => (
                                                                <div className={`col-12 single-analytics ${bg}`} key={i}>
                                                                    <div className="row">
                                                                        <div className="col-12"><img src={icon} /> <span>{name}</span><span className="score">{score}</span></div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                ))
            }
            <section className="container-fluid blog-end">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p>Each of India's top cities offers a distinctive tapestry of <span className="text--App-Green">opportunities</span>, <span className="text--Primary-primary-500">lifestyles</span>, and <span className="text--App-Orange">challenges</span> for students and young professionals. Whether you prioritize academic excellence, career opportunities, quality of life, or cultural richness, there's a city that suits your <span className="text--App-Pink">preferences and aspirations.</span></p>
                            {blog1Bottompart.map((p, key) => (<p key={key}>{p}</p>))}
                            <p>By considering the nuanced pros and cons outlined above, along with individual preferences and aspirations, you can make an informed decision and embark on a fulfilling journey in the city of your choice.</p>
                            <p>Remember, the perfect city is not merely defined by its <span className="text--Primary-primary-500">amenities</span> but by the <span className="text--App-Purple">experiences</span> and <span className="text--App-Green">growth</span> it offers along the way.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Upnext relatedBlog={relatedBlog} ids={[1]} />
        </>
    )
}

export default Blog1;