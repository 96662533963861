import Cards from '../../assets/cards.png';
import PlayStore from '../../assets/playstore.png';
import AppStore from '../../assets/appstore.png';
import av1 from '../../assets/av1.png';
import av2 from '../../assets/av2.png';
import av3 from '../../assets/av3.png';
import av4 from '../../assets/av4.png';
import './Hero.css';
const Hero = () => {

    const avatars = [av1, av2, av3, av4];
    return (
        <section className="container-fluid mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h1 className="hero-text hero-text-font">
                            Find Flat and Flatmates<br />with <b>Zero Brokerage!</b>
                        </h1>
                        <h6 className="hero-sub-text hero-sub-text-font">Wrong flatmates, No more!<br />– we've got your back.</h6>
                    </div>
                </div>
            </div>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-lg-7 text-center '>
                                <img src={Cards} className='hero-cards' />
                            </div>
                            <div className='col-lg-4 col-md-6 offset-md-3 offset-lg-1 hero-components'>
                                <div className='row'>
                                    <div className="col-12 text-center store-links">
                                        <a href='https://joinfs.app/s/s5' target='_blank'><img src={PlayStore} alt='' /></a>
                                        <a href='https://joinfs.app/s/s5' target='_blank'><img src={AppStore} alt='' /></a>
                                    </div>
                                    <div className='col-12'>
                                        <div className='bento'>
                                            <h4>Save yourself from wrong<br /> flatmates & your money<br /> from brokerage fees!</h4>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='social-band'>
                                            <div className='d'>
                                                {avatars.map((img, key) => (<img key={key} src={img} alt='' />))}
                                            </div>
                                            <div className='f'>
                                                <h3>10K+</h3>
                                                <p>Flatmates Matched</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;