import PlayStore from '../../assets/playstore.png';
import AppStore from '../../assets/appstore.png';

import './HappySharing.css';
const HappySharing = () => {
    return (
        <section className="container-fluid happy-container">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1 className='happy-header hero-text-font'>Happy <span>Flatsharing!</span></h1>
                    </div>
                    <div className="col-md-4 offset-md-4 text-center store-links">
                        <a href='https://joinfs.app/s/s5' target='_blank'><img src={PlayStore}  alt=''/></a>
                        <a href='https://joinfs.app/s/s5' target='_blank'><img src={AppStore} alt=''/></a>
                    </div>
                    <div className="col-12 text-center">
                        <h6 className='happy-subtext hero-sub-text-font'>
                            Wrong flatmates, No more!
                        </h6>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HappySharing;