export const faqs = [
    {
        title: "What is Flatshare?",
        content: [
            "Flatshare is an app where you can <b>find your perfect flat/house for rent</b> without paying any brokerage and compatible flatmates & roommates, who share your interests, languages & lifestyle.",
            "But it doesn’t end there, more than an app, ",
            "Flatshare is a community of individuals who value their living environment and the quality of their interpersonal relationships.",
        ]
    },
    {
        title: "Why use Flatshare?",
        content: [
            "🤝‍<b>  Personalized Matches:</b> Our advanced matching system analyses your profile to match you with a house for rent with flatmates & roommates who share your lifestyle, interests, and values.",
            "🔒 <b>Secure and Verified:</b> We prioritize your safety by verifying profiles and secure in-app messaging, so you can get to know potential flatmates & roommates before meeting.",
            "🌐 <b>Vibrant Community:</b> Join a thriving community of individuals who value their living environment and the quality of their interpersonal relationships.",
            "🚫 <b>NoBroker, NoFake Listings:</b> Flatshare eliminates spam, scams, and fake profiles, allowing you to focus on finding your perfect flat and flatmates.",
            "Flatshare is more than just sharing a space; it's about finding the right flatmates to share your life with. Remember, a happy home starts with the right flatmates.",
            "Download the Flatshare app today and start living your best life!"
        ]
    },
    {
        title: "Why did you create Flatshare?",
        content: [
            "Who you live with = Who you’ll become.",
            "People you surround yourself with can have a huge impact on your life.",
            "That's why we created flatshare - to make your flat and flatmate search as simple and fun as possible, without you paying any brokerage.",
            "So that you can <u>LIVE YOUR BEST LIFE</u>."
        ]
    },
    {
        title: "How does Flatshare help you find your Perfect Flat & Flatmate?",
        content: [
            "Flatshare uses an intelligent matching algorithm that only gives you the top recommendations based on your profile and preferences.",
            "This eliminates the need to search through an overwhelming number of options, making your flat and flatmate search personalised for you."
        ]
    },
    {
        title: "Can I search for flatmates/flats near me?",
        content: [
            "Yes, you can search for flats & flatmates in any specific area in India."
        ]
    },
    {
        title: "Can I share feedback or a feature request?",
        content: [
            "Absolutely! We value your feedback and are always striving to improve Flatshare.",
            "If you have any suggestions, feature requests, or feedback, please reach out to us at <a href='mailto:hello@flatshare.club'>hello@flatshare.club</a>. We'd love to hear from you."
        ]
    }
]