import { faqs } from './FAQ.constants';
import './FAQ.css';
const FAQ = () => {
    return (
        <section className="container-fluid faq-container">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className='row'>
                            <div className="col-12">
                                <h1 className='faq-header section-header-font'>You Asked <span>We Answer!</span></h1>
                            </div>
                            <div className="col-12">
                                <div className='row'>
                                    {
                                        faqs.map(({ title, content }, key) => (
                                            <div key={key} className='col-12 single-faq'>
                                                <h4 className='faq-list-title'>{title}</h4>
                                                {
                                                    content.map((p, i) => (
                                                        <p key={i} className='faq-list-content' dangerouslySetInnerHTML={{ __html: p }} ></p>
                                                    ))
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default FAQ;