import SEO from './SEO';
const Terms = () => {
    return (
        <>
        <SEO title='Terms of Use | FlatShare' />
            <section className="container-fluid page-container">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h4 className="page-header">Last updated 12th of July, 2021</h4>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Acceptance of the Terms of Use</h5>
                            <p>These terms of use are entered into by and between You and Flatshare Internet Private Limited. ("Company," "we," or "us"). The following terms and conditions (collectively, "Terms of Use") govern your access to and use of the Flatshare mobile application (the "App"), whether as a guest or a registered user.</p>
                            <p>Please read the Terms of Use carefully before you start to use the App. By using the App or by joining the early access or by clicking to accept or agree to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at joinflatshare.com/privacy , incorporated herein by reference. If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the App</p>
                            <p>This App is offered and available to users who are 15 years of age or older. By using this App, you represent and warrant that you meet all of the App’s eligibility requirements. If you do not meet all of these requirements, you must not access or use the App.</p>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Changes to the Terms of Use</h5>
                            <p>We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the App thereafter.</p>
                            <p>Your continued use of the App following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you.</p>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Account Security</h5>
                            <p>If you choose a name, number or any other piece of information as part of our security procedures, you must treat that information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this App or portions of it using your number, or other security information. You agree to notify us immediately of any unauthorized access to or use of your number or any other breach of security.</p>
                            <p>We have the right to disable any number or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.</p>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Intellectual Property Rights</h5>
                            <p>The App and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by the Company and are protected intellectual property laws.</p>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Prohibited Uses</h5>
                            <p>You may use the App only for lawful purposes and in accordance with these Terms of Use. You agree not to use the App:</p>
                            <ul>
                                <li>In any way that violates any applicable law or regulation.</li>
                                <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.</li>
                                <li>To send, knowingly receive, upload, download, use, or re-use any material that does not comply with the Content Standards set out in these Terms of Use.</li>
                                <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter," "spam," or any other similar solicitation.</li>
                                <li>To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity (including, without limitation, by using their name associated with any of the foregoing).</li>
                                <li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the App, or which, as determined by us, may harm the Company or users of the App, or expose them to liability.</li>
                            </ul>
                            <p>Additionally, you agree not to:</p>
                            <ul>
                                <li>Use the App in any manner that could disable, overburden, damage, or impair the App or interfere with any other party's use of the App, including their ability to engage in real time activities through the App.</li>
                                <li>Use any robot, spider, or other automatic device, process, or means to access the App for any purpose, including monitoring or copying any of the material on the App.</li>
                                <li>Use any manual process to monitor or copy any of the material on the App, or for any other purpose not expressly authorized in these Terms of Use, without our prior written consent.</li>
                                <li>Use any device, software, or routine that interferes with the proper working of the App.</li>
                                <li>Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</li>
                                <li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the App, the server on which the App is stored, or any server, computer, or database connected to the App.</li>
                                <li>Attack the App via a denial-of-service attack or a distributed denial-of-service attack.</li>
                                <li>Otherwise attempt to interfere with the proper working of the App.</li>
                            </ul>
                        </div>
                        <div className="col-12 page-section">
                            <h5>User Contributions</h5>
                            <p>The App contains interactive features ("Interactive Services") that allow users to post, submit, publish, display, or transmit to other users or other persons (hereinafter, "card") content or materials (collectively, "User Contributions") on or through the App.</p>
                            <p>All User Contributions must comply with the Content Standards set out in these Terms of Use.</p>
                            <p>Any User Contribution you post while using the App will be considered non-confidential and non-proprietary.</p>
                            <p>You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not the Company, have full responsibility for such content, including its legality, reliability, accuracy, and appropriateness.</p>
                            <p>We are not responsible or liable to any third party for the content or accuracy of any User Contributions posted by you or any other user of the App.</p>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Monitoring and Enforcement; Termination</h5>
                            <p>We have the right to:</p>
                            <ul>
                                <li>Remove or refuse to post any User Contributions for any or no reason in our sole discretion.</li>
                                <li>Take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contribution violates the Terms of Use, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the App or the public, or could create liability for the Company.</li>
                                <li>Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.</li>
                                <li>Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the App.</li>
                                <li>Terminate or suspend your access to all or part of the App for any or no reason, including without limitation, any violation of these Terms of Use.</li>
                            </ul>
                            <p>We will:</p>
                            <ul>
                                <li>Investigate reports submitted to us of objectionable content or user within 24 hours of receipt; and</li>
                                <li>Remove the user who has been reported to us if we decide (at our sole discretion) that the user has breached the Terms of Use.</li>
                                <li>Without limiting the foregoing, we have the right to cooperate fully with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the App. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.</li>
                            </ul>
                            <p>However, we cannot review all material before it is posted on the App, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.</p>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Content Standards</h5>
                            <p>These content standards apply to any and all User Contributions and use of Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, local, and international laws and regulations. Without limiting the foregoing, User Contributions must not:</p>
                            <ul>
                                <li>Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable.</li>
                                <li>Infringe any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person.</li>
                                <li>Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use and our Privacy Policy.</li>
                                <li>Promote any illegal activity, or advocate, promote, or assist any unlawful act.</li>
                                <li>Impersonate any person, or misrepresent your identity or affiliation with any person or organization.</li>
                                <li>Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.</li>
                            </ul>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Copyright Infringement</h5>
                            <p>If you believe that any User Contributions violate your copyright, please see our Copyright Policy below for instructions on sending us a notice of copyright infringement. It is the policy of the Company to terminate the user accounts of repeat infringers.</p>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Changes to the App</h5>
                            <p>We may update the content on this App from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the App may be out of date at any given time, and we are under no obligation to update such material.</p>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Disclaimer of Warranties</h5>
                            <p>You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the App will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our App for any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE APP OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE APP OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY APP LINKED TO IT.</p>
                            <p>YOUR USE OF THE APP, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE APP IS AT YOUR OWN RISK. THE APP, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE APP ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE APP. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE APP, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE APP WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR APP OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE APP OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE APP WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
                            <p>TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</p>
                            <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Limitation on Liability</h5>
                            <p>TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE APP, ANY APPS LINKED TO IT, ANY CONTENT ON THE APP OR SUCH OTHER APPS, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.</p>
                            <p>The limitation of liability set out above does not apply to liability resulting from our gross negligence or willful misconduct.</p>
                            <p>THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Indemnification</h5>
                            <p>You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the App, including, but not limited to, your User Contributions, any use of the App's content, services, and products other than as expressly authorized in these Terms of Use, or your use of any information obtained from the App.</p>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Limitation on Time to File Claims</h5>
                            <p>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE APP MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</p>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Waiver and Severability</h5>
                            <p>No waiver by the Company of any term or condition set out in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</p>
                            <p>If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.</p>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Entire Agreement</h5>
                            <p>The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you and Flatshare Internet Private Limited. regarding the App and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the App.</p>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Your Comments and Concerns</h5>
                            <p>This App is operated by Flatshare Internet Private Limited.</p>
                            <p>All notices of copyright infringement claims should be sent to <a href="mailto:feedback@flatshare.club">feedback@flatshare.club</a></p>
                            <p>All other feedback, comments, requests for technical support, and other communications relating to the App should be directed to: <a href="mailto:feedback@flatshare.club">feedback@flatshare.club</a> as well.</p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Terms;