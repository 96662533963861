import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Header from './layouts/header/Header';
import Footer from './layouts/footer/Footer';
import Home from './pages/Home';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';

import { relatedBlog } from './pages/Blog/Blog.constants';
import './App.css';
import './Responsive.css';

function App() {
  return (
    <>
      <HelmetProvider>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route index element={<Home />} />
            <Route path="privacy-policy" element={<Privacy />} />
            <Route path="terms-and-condition" element={<Terms />} />
            {relatedBlog.map(({ link, comp }) => (<Route path={link} element={comp} />))}
            {/*<Route path='*' element={<PageNotFound />} />*/}
          </Routes>
          <Footer />
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
}

export default App;
