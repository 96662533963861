import './Upnext.css';
import { Link } from "react-router-dom";
const Upnext = (props) => {
    const renderBlogs = props?.ids ? props?.relatedBlog.filter(({ id }) => props?.ids?.includes(id)) : props?.relatedBlog;
    return (
        <>
            {renderBlogs && <section className='container-fluid up-next-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 up-next-section-header'>
                            {
                                props.header ? <h1 className='customh1' dangerouslySetInnerHTML={{ __html: props.header }}></h1> : <h2>Up next</h2>
                            }

                        </div>
                        <div className="col-md-11 col-12">
                            <div className="row">
                                <div className='col-12 section-tile-area'>
                                    <div className='row'>
                                        {
                                            renderBlogs.map(({ img, title, link }, i) => (
                                                <Link to={link} className={`col-md-6 col-12 white-box-tile-parent`} key={i}>
                                                    <div className='white-box-tile'>
                                                        <img src={img} />
                                                        <p>{title}</p>
                                                    </div>
                                                </Link>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
        </>
    )
};

export default Upnext;