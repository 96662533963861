import { images } from './Blog.images';
import Blog1 from './Blog1';
import Blog2 from './Blog2';


export const responsive = {
    widedesktop: {
        breakpoint: { max: 4000, min: 1200 },
        items: 2,
        partialVisibilityGutter: 100 // this is needed to tell the amount of px that should be visible.
    },
    desktop: {
        breakpoint: { max: 1199, min: 992 },
        items: 1,
        partialVisibilityGutter: 400 // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
        breakpoint: { max: 991, min: 768 },
        items: 1,
        partialVisibilityGutter: 300 // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 1,
        partialVisibilityGutter: 70 // this is needed to tell the amount of px that should be visible.
    }
}

export const getAnalytics = (a, b, c, d, e, f, g) => {
    const fullMarks = 5;
    const analytics = [
        {
            icon: images.ico1,
            bg: 'bg--Primary-primary-500',
            name: 'Education Institutions',
            score: a + '/' + fullMarks
        },
        {
            icon: images.ico2,
            bg: 'bg--App-Pink',
            name: 'Job Market',
            score: b + '/' + fullMarks
        },
        {
            icon: images.ico3,
            bg: 'bg--App-Orange',
            name: 'Infrastructure',
            score: c + '/' + fullMarks
        },
        {
            icon: images.ico4,
            bg: 'bg--App-Purple',
            name: 'Cultural Attractions',
            score: d + '/' + fullMarks
        },
        {
            icon: images.ico5,
            bg: 'bg--Primary-primary-500',
            name: 'Climate',
            score: e + '/' + fullMarks
        },
        {
            icon: images.ico6,
            bg: 'bg--App-Pink',
            name: 'Quality of Life',
            score: f + '/' + fullMarks
        },
        {
            icon: images.ico7,
            bg: 'bg--App-Orange',
            name: 'Overall Growth and Development',
            score: g + '/' + fullMarks
        }
    ];

    const totalMarks = fullMarks * analytics.length;
    analytics.push({
        icon: images.ico8,
        bg: 'bg--App-Green',
        name: 'Final Score',
        score: a + b + c + d + e + f + g + '/' + totalMarks
    })
    return analytics;

}

export const getCities = () => ([
    {
        name: 'Bengaluru',
        description: [
            'The capital of Karnataka, is renowned for its booming IT industry, earning it the title of "Silicon Valley of India." ',
            'The city is home to numerous multinational corporations, start-ups, and tech parks, offering a plethora of job opportunities for professionals in the technology sector. Apart from its thriving economy, Bengaluru boasts a pleasant climate, lush greenery, and a cosmopolitan culture.',
            'The presence of top educational institutions, world-class healthcare facilities, and a vibrant nightlife scene further enhance its appeal.',
            'The cost of living in Bengaluru is relatively high compared to other Indian cities, with average monthly expenses ranging from ₹20,000 to ₹30,000 for a single person.',
        ],
        sliderSection: [
            {
                name: 'Top Educational Institutions',
                slides: [
                    {
                        img: images.bangcol1,
                        name: 'Indian Institute of Management Bangalore (IIMB)'
                    },
                    {
                        img: images.bangcol2,
                        name: 'Indian Institute of Science (IISc) Christ University'
                    },
                    {
                        img: images.bangcol3,
                        name: 'Bangalore University'
                    },
                    {
                        img: images.bangcol4,
                        name: 'Ramaiah Institute of Technology (RIT)'
                    },
                ],
            },
            {
                name: 'Top Cultural Attractions',
                slides: [
                    {
                        img: images.bangat1,
                        name: 'Lalbagh Botanical Garden'
                    },
                    {
                        img: images.bangat2,
                        name: 'Bangalore Palace'
                    },
                    {
                        img: images.bangat3,
                        name: 'Cubbon Park'
                    },
                    {
                        img: images.bangat4,
                        name: "Tipu Sultan's Summer Palace"
                    },
                    {
                        img: images.newim1,
                        name: "Visvesvaraya Industrial and Technological Museum"
                    },
                ]
            },
        ],
        pros: [
            'Renowned for its prestigious institutions like Indian Institute of Management Bangalore (IIMB) and Bangalore University, Bengaluru stands as a hub for technological innovation and entrepreneurship',
            'The city boasts a vibrant job market in the tech industry, offering unparalleled career prospects for young professionals.',
            'With its pleasant climate, diverse cultural scene, and burgeoning culinary landscape, Bengaluru fosters a dynamic and cosmopolitan lifestyle.'
        ],
        cons: [
            'However, Bengaluru’s rapid urbanization has led to issues such as high cost of living, traffic congestion, and environmental concerns.',
            "Navigating through the city's bustling streets can be a challenge, but for many, the opportunities it presents outweigh these drawbacks.",
        ],
        analytics: getAnalytics(4, 5, 5, 4, 4, 4, 4)
    },
    {
        name: 'Mumbai',
        description: [
            'As the financial capital of India, Mumbai exudes a charm that is unmatched. From the iconic Gateway of India to the bustling streets of Bandra, this city never fails to mesmerize its residents.',
            'With a dynamic blend of cultures, cuisines, and lifestyles, Mumbai offers an unparalleled experience to its dwellers. Despite the fast-paced life, Mumbai embraces everyone with open arms, making it a melting pot of opportunities and dreams.',
            'The average monthly expenses in Mumbai range from ₹25,000 to ₹40,000, making it one of the most expensive cities in India.',
        ],
        sliderSection: [
            {
                name: 'Top Educational Institutions',
                slides: [
                    {
                        img: images.mumcol1,
                        name: 'Indian Institute of Technology Bombay (IIT Bombay)'
                    },
                    {
                        img: images.mumcol2,
                        name: 'Veermata Jijabai Technological Institute(VJTI)'
                    },
                    {
                        img: images.mumcol3,
                        name: 'University of Mumbai'
                    },
                    {
                        img: images.newim2,
                        name: 'Tata Institute of Social Sciences (TISS)'
                    },
                    {
                        img: images.newim3,
                        name: 'Jamnalal Bajaj Institute of Management Studies (JBIMS)'
                    },
                ],
            },
            {
                name: 'Top Cultural Attractions',
                slides: [
                    {
                        img: images.mumat1,
                        name: 'Gateway of India'
                    },
                    {
                        img: images.mumat2,
                        name: 'Elephanta Caves'
                    },
                    {
                        img: images.mumat3,
                        name: 'Marine Drive'
                    },
                    {
                        img: images.newim4,
                        name: 'Chhatrapati Shivaji Maharaj Vastu Sangrahalaya (formerly Prince of Wales Museum)'
                    },
                    {
                        img: images.a,
                        name: 'Siddhivinayak Temple'
                    },
                ]
            },
        ],
        pros: [
            "Mumbai's allure lies in its prestigious educational institutions like the IIT Bombay and Tata Institute of Social Sciences (TISS), coupled with abundant job opportunities in finance, media, and entertainment sectors.",
            'The city pulsates with energy, offering a rich tapestry of cultural experiences, vibrant nightlife, and world-class dining options.',
        ],
        cons: [
            "However, the city's skyrocketing real estate prices, overcrowded streets, and relentless pace can be overwhelming for some.",
            "Yet, for those craving a fast-paced lifestyle and boundless opportunities, Mumbai remains an irresistible destination."
        ],
        analytics: getAnalytics(4, 5, 4, 5, 3, 3, 4)
    },
    {
        name: 'Delhi',
        description: [
            'Delhi, the capital city of India, is a bustling metropolis teeming with opportunities for young professionals. Home to a diverse range of industries, including finance, IT, media, and healthcare, Delhi offers abundant job prospects and career growth opportunities.',
            "The city's vibrant culture, historical landmarks, and cosmopolitan lifestyle make it an exciting destination for ambitious professionals seeking to make their mark in their respective fields. ",
            'With world-class educational institutions, excellent healthcare facilities, and a thriving social scene, Delhi ensures a dynamic and fulfilling lifestyle for young professionals looking to build their careers and explore new horizons',
            'The cost of living in Delhi is relatively high, with average monthly expenses ranging from ₹20,000 to ₹30,000.',
        ],
        sliderSection: [
            {
                name: 'Top Educational Institutions',
                slides: [
                    {
                        img: images.delcol1,
                        name: 'Indian Institute of Technology Delhi (IIT Delhi)'
                    },
                    {
                        img: images.delcol2,
                        name: 'All India Institute of Medical Sciences (AIIMS)'
                    },
                    {
                        img: images.delcol3,
                        name: 'Shri Ram College of Commerce(SRCC)'
                    },
                    {
                        img: images.b,
                        name: 'Jawaharlal Nehru University (JNU)'
                    },
                    {
                        img: images.c,
                        name: 'Delhi Technological University (DTU)'
                    },
                ],
            },
            {
                name: 'Top Cultural Attractions',
                slides: [
                    {
                        img: images.delat1,
                        name: 'India Gate'
                    },
                    {
                        img: images.delat2,
                        name: 'Qutub Minar'
                    },
                    {
                        img: images.delat3,
                        name: 'Red Fort'
                    },
                    {
                        img: images.d,
                        name: 'Humayun’s Tomb'
                    },
                    {
                        img: images.e,
                        name: 'Lotus Temple'
                    },
                ]
            },
        ],
        pros: [
            "Delhi boasts a rich academic landscape with institutions like the IIT Delhi, All India Institute of Medical Sciences (AIIMS), Shri Ram College of Commerce(SRCC) and Jawaharlal Nehru University (JNU), alongside a thriving job market spanning various sectors.",
            "The city's eclectic mix of historical landmarks, bustling markets, and cultural festivals provides a rich tapestry of experiences for residents."
        ],
        cons: [
            "Nevertheless, Delhi grapples with challenges such as air pollution, traffic congestion, and safety concerns.",
            "Despite these drawbacks, its status as a melting pot of cultures and opportunities continues to attract students and professionals seeking growth and exploration."
        ],
        analytics: getAnalytics(4, 4, 3, 5, 3, 3, 4)
    },
    {
        name: 'Pune',
        description: [
            "Home to some of the country's finest educational institutions, Pune has earned the moniker of the Oxford of the East. Apart from its esteemed universities and research centers, Pune enchants its residents with its rich history, vibrant culture, and delectable cuisine.",
            "The city strikes a perfect balance between modernity and tradition, offering a plethora of recreational activities, thriving job market, and a serene environment for its inhabitants.",
            "Pune's cost of living is relatively affordable compared to other metro cities, with average monthly expenses ranging from ₹15,000 to ₹25,000.",
        ],
        sliderSection: [
            {
                name: 'Top Educational Institutions',
                slides: [
                    {
                        img: images.punecol1,
                        name: 'College of Engineering, Pune (COEP)'
                    },
                    {
                        img: images.punecol2,
                        name: 'University of Pune'
                    },
                    {
                        img: images.punecol3,
                        name: 'Symbiosis International University'
                    },
                    {
                        img: images.e,
                        name: 'Film and Television Institute of India (FTII)'
                    },
                    {
                        img: images.g,
                        name: 'Bharati Vidyapeeth Deemed University'
                    },
                ],
            },
            {
                name: 'Top Cultural Attractions',
                slides: [
                    {
                        img: images.puneat1,
                        name: 'Shaniwar Wada'
                    },
                    {
                        img: images.puneat2,
                        name: 'Aga Khan Palace'
                    },
                    {
                        img: images.puneat3,
                        name: 'Sinhagad Fort'
                    },
                    {
                        img: images.h,
                        name: 'Raja Dinkar Kelkar Museum'
                    },
                    {
                        img: images.i,
                        name: 'Pataleshwar Cave Temple'
                    },
                ]
            },
        ],
        pros: [
            "Pune's educational prowess is evident through institutions like the University of Pune and Symbiosis International University, offering a conducive environment for academic pursuits.",
            "The city's moderate climate, affordable cost of living, and thriving student culture create an enriching ecosystem for learning and personal development."
        ],
        cons: [
            "However, Pune's public transportation infrastructure may not match the standards of larger metros, posing challenges for daily commuters.",
            "Nonetheless, its academic excellence and burgeoning startup ecosystem make it a compelling destination for those seeking a balanced lifestyle."
        ],
        analytics: getAnalytics(4, 4, 3, 3, 4, 4, 3)
    },
    {
        name: 'Hyderabad',
        description: [
            "Hyderabad, the capital of Telangana, is renowned for its rich history, exquisite cuisine, and booming IT industry. The city's iconic landmarks, including the Charminar, Golconda Fort, and Hussain Sagar Lake, reflect its glorious past.",
            "Hyderabad's thriving IT sector, coupled with its affordable cost of living, makes it an attractive destination for young professionals and entrepreneurs. The city's vibrant arts scene, bustling markets, and warm hospitality add to its charm, making it a top choice for expatriates and locals alike.",
            "Hyderabad's cost of living is relatively affordable compared to other metro cities, with average monthly expenses ranging from ₹15,000 to ₹25,000.",
        ],
        sliderSection: [
            {
                name: 'Top Educational Institutions',
                slides: [
                    {
                        img: images.hycol1,
                        name: 'Indian School of Business (ISB)'
                    },
                    {
                        img: images.hycol2,
                        name: 'University of Hyderabad'
                    },
                    {
                        img: images.hycol3,
                        name: 'Osmania University'
                    },
                    {
                        img: images.j,
                        name: 'International Institute of Information Technology (IIIT)'
                    },
                    {
                        img: images.k,
                        name: 'Indian Institute of Technology Hyderabad (IIT Hyderabad)'
                    },
                ],
            },
            {
                name: 'Top Cultural Attractions',
                slides: [
                    {
                        img: images.hyat1,
                        name: 'Charminar'
                    },
                    {
                        img: images.hyat2,
                        name: 'Golconda Fort'
                    },
                    {
                        img: images.hyat3,
                        name: 'Ramoji Film City'
                    },
                    {
                        img: images.l,
                        name: 'Salar Jung Museum'
                    },
                    {
                        img: images.m,
                        name: 'Chowmahalla Palace'
                    },
                ]
            },
        ],
        pros: [
            "Hyderabad's educational landscape boasts institutions like the University of Hyderabad and International Institute of Information Technology (IIIT), complemented by a booming IT sector and affordable living costs.",
            "The city's blend of modernity and tradition, along with its delectable cuisine and historical landmarks, offers a unique living experience."
        ],
        cons: [
            "Yet, rapid urbanization has resulted in traffic congestion and infrastructure strains, impacting the city's livability.",
            "Nevertheless, Hyderabad's warmth and opportunities continue to attract individuals seeking growth and cultural immersion."
        ],
        analytics: getAnalytics(4, 4, 3, 4, 4, 3, 3)
    },
    {
        name: 'Chennai',
        description: [
            "Chennai, situated on the Coromandel Coast, is known for its rich cultural heritage, pristine beaches, and thriving automobile industry. The city's colonial-era architecture, vibrant arts scene, and mouth-watering cuisine reflect its eclectic mix of tradition and modernity.",
            "Chennai's robust economy, coupled with its world-class healthcare facilities and educational institutions, makes it an ideal destination for professionals and families seeking a high quality of life.",
            "The city's warm climate and welcoming locals further enhance its appeal, making it a top choice for expatriates and tourists alike.",
            "Chennai's cost of living is relatively affordable compared to other metro cities, with average monthly expenses ranging from ₹15,000 to ₹25,000.",
        ],
        sliderSection: [
            {
                name: 'Top Educational Institutions',
                slides: [
                    {
                        img: images.chcol1,
                        name: 'Indian Institute of Technology Madras (IITM)'
                    },
                    {
                        img: images.chcol2,
                        name: 'Anna University'
                    },
                    {
                        img: images.chcol3,
                        name: 'Loyola College'
                    },
                    {
                        img: images.n,
                        name: 'Madras Christian College (MCC)'
                    },
                    {
                        img: images.o,
                        name: 'Dr. Ambedkar Government Law College'
                    },
                ],
            },
            {
                name: 'Top Cultural Attractions',
                slides: [
                    {
                        img: images.chat1,
                        name: 'Marina Beach'
                    },
                    {
                        img: images.chat2,
                        name: 'Kapaleeshwarar Temple'
                    },
                    {
                        img: images.chat3,
                        name: 'San Thome Basilica'
                    },
                    {
                        img: images.p,
                        name: 'Government Museum, Chennai'
                    },
                    {
                        img: images.q,
                        name: 'Valluvar Kottam'
                    },
                ]
            },
        ],
        pros: [
            "Chennai's academic legacy is upheld by institutions like Indian Institute of Technology Madras (IITM) and Anna University, supported by a diverse job market spanning IT, manufacturing, and healthcare sectors.",
            "The city's rich cultural heritage, pristine beaches, and thriving arts scene offer a blend of intellectual stimulation and leisure."
        ],
        cons: [
            "However, Chennai's scorching summers and occasional water scarcity may pose challenges for residents.",
            "Nonetheless, its academic excellence and vibrant lifestyle make it an enticing destination for students and professionals alike."
        ],
        analytics: getAnalytics(4, 4, 3, 4, 3, 3, 3)
    },
    {
        name: 'Kolkata',
        description: [
            "Kolkata, the City of Joy, is renowned for its intellectual vibrancy and rich cultural heritage. With prestigious institutions like Indian Statistical Institute (ISI), Kolkata offers a stimulating environment for academic pursuits.",
            "Students can immerse themselves in Kolkata's colonial-era architecture, literary festivals, and mouth-watering cuisine while pursuing their education. With a thriving IT and finance sector, coupled with a vibrant arts scene, Kolkata provides students with diverse internship and career prospects.",
            "Kolkata's cost of living is relatively affordable compared to other metro cities, with average monthly expenses ranging from ₹12,000 to ₹20,000.",
        ],
        sliderSection: [
            {
                name: 'Top Educational Institutions',
                slides: [
                    {
                        img: images.kolcol1,
                        name: 'Jadavpur University'
                    },
                    {
                        img: images.kolcol2,
                        name: 'Indian Statistical Institute (ISI)'
                    },
                    {
                        img: images.kolcol3,
                        name: 'University of Calcutta'
                    },
                    {
                        img: images.r,
                        name: 'Presidency University'
                    },
                    {
                        img: images.s,
                        name: 'Indian Institute of Management Calcutta (IIM Calcutta)'
                    },
                ],
            },
            {
                name: 'Top Cultural Attractions',
                slides: [
                    {
                        img: images.kolat1,
                        name: 'Victoria Memorial'
                    },
                    {
                        img: images.kolat2,
                        name: 'Howrah Bridge'
                    },
                    {
                        img: images.kolat3,
                        name: 'Dakshineswar Kali Temple'
                    },
                    {
                        img: images.t,
                        name: 'Indian Museum'
                    },
                    {
                        img: images.u,
                        name: 'Belur Math'
                    },
                ]
            },
        ],
        pros: [
            "Kolkata's academic landscape is marked by institutions like Indian Statistical Institute (ISI), accompanied by job opportunities in IT, finance, and media sectors.",
            "The city's colonial-era architecture, literary traditions, and cultural festivals create a captivating backdrop for residents."
        ],
        cons: [
            "Yet, Kolkata grapples with infrastructural limitations and a slower pace of development compared to other metros.",
            "Nevertheless, its cultural richness and intellectual fervor make it a charming haven for those seeking a blend of history and modernity."
        ],
        analytics: getAnalytics(4, 3, 3, 4, 3, 3, 3)
    },
    {
        name: 'Ahmedabad',
        description: [
            "Ahmedabad, Gujarat's largest city, is renowned for its rich cultural heritage, vibrant festivals, and thriving textile industry. The city's UNESCO World Heritage sites, including the iconic Sabarmati Ashram and Adalaj Stepwell, attract history enthusiasts from around the globe.",
            "Ahmedabad's booming textile and pharmaceutical industries, coupled with its low cost of living, make it an attractive destination for professionals and entrepreneurs. The city's warm hospitality, delectable cuisine, and traditional Gujarati charm add to its allure, making it a top choice for those seeking a blend of tradition and progress.",
            "Ahmedabad's cost of living is relatively affordable compared to other metro cities, with average monthly expenses ranging from ₹12,000 to ₹20,000.",
        ],
        sliderSection: [
            {
                name: 'Top Educational Institutions',
                slides: [
                    {
                        img: images.amcol1,
                        name: 'Indian Institute of Management Ahmedabad (IIMA)'
                    },
                    {
                        img: images.amcol2,
                        name: 'Gujarat University'
                    },
                    {
                        img: images.amcol3,
                        name: 'National Institute of Design (NID)'
                    },
                    {
                        img: images.v,
                        name: 'CEPT University'
                    },
                    {
                        img: images.w,
                        name: 'Gujarat Technological University (GTU)'
                    },
                ],
            },
            {
                name: 'Top Cultural Attractions',
                slides: [
                    {
                        img: images.amat1,
                        name: 'Sabarmati Ashram'
                    },
                    {
                        img: images.amat2,
                        name: 'Adalaj Stepwell'
                    },
                    {
                        img: images.amat3,
                        name: 'Sarkhej Roza'
                    },
                    {
                        img: images.x,
                        name: 'Kankaria Lake'
                    },
                    {
                        img: images.y,
                        name: 'Calico Museum of Textiles'
                    },
                ]
            },
        ],
        pros: [
            "Ahmedabad's educational prowess shines through institutions like Indian Institute of Management Ahmedabad (IIMA) and National Institute of Design (NID), supported by emerging job opportunities in textiles, pharmaceuticals, and startups.",
            "The city's rich cultural heritage, bustling markets, and affordable lifestyle offer a unique blend of tradition and progress."
        ],
        cons: [
            "Nevertheless, Ahmedabad's limited public transportation and extreme summer temperatures may pose challenges for residents.",
            "Nonetheless, its entrepreneurial spirit and cultural vibrancy continue to attract individuals seeking opportunities and cultural immersion."
        ],
        analytics: getAnalytics(3, 3, 3, 3, 4, 3, 3)
    }
]);

export const blog1Bottompart = [
    "Bengaluru emerges as a top choice for those seeking a thriving career in the tech industry, with its pleasant climate and cosmopolitan culture adding to its appeal.",
    "Mumbai, the financial capital, offers unparalleled opportunities across various sectors, along with a vibrant cultural scene and buzzing nightlife.",
    "Delhi, the national capital, attracts professionals with its diverse job market and rich cultural heritage, despite its challenges of pollution and safety concerns.",
    "Pune, with its pleasant weather and affordable living, is a haven for students and professionals alike, offering a balanced lifestyle.",
    "Hyderabad, the pearl city, is a perfect blend of history and modernity, with its thriving IT sector and delectable cuisine.",
    "Chennai, the Detroit of India, is known for its strong manufacturing base and beautiful beaches, offering a unique mix of opportunities.",
    "Kolkata, the cultural capital, is a paradise for art and literature enthusiasts, with its affordable living and delectable cuisine.",
    "Ahmedabad, with its rich heritage and growing industries, is an emerging hub for professionals and entrepreneurs.",
    "In terms of the best areas to live in each city, it's essential to consider factors like proximity to the workplace, safety, accessibility, and amenities. Some popular residential areas in these cities include:",
    "Bengaluru: Indiranagar, Koramangala, HSR Layout, Jayanagar",
    "Mumbai: Bandra, Andheri, Powai, Thane, Navi Mumbai",
    "Delhi: South Delhi, Vasant Kunj, Saket, Gurgaon, Noida",
    "Pune: Koregaon Park, Baner, Kothrud, Viman Nagar",
    "Hyderabad: Banjara Hills, Jubilee Hills, Gachibowli, HITEC City",
    "Chennai: Adyar, OMR, Velachery, Anna Nagar",
    "Kolkata: Salt Lake, New Town, Ballygunge, Park Street",
    "Ahmedabad: Satellite, Prahlad Nagar, Navrangpura, Vastrapur",
    
]

export const blog2Content = [
    {
        header: 'Why Flatmates Matters: Who you live with = Who you’ll become.',
        ul: [
            "Moving into a new place can be both exciting and daunting.",
            "Your flatmates significantly influence your living experience.",
            "Whether you're a student, a young professional, or someone in between, having compatible flatmates can make a world of difference.",
            "They can become your friends, support system, and companions on this journey called life."
        ],
        mainClasses: 'col-12',
    },
    {
        header: 'The Challenges of Finding Flatmates & Roommates.',
        ul: [
            "Traditionally, finding flatmates & roommates involves scouring through classified ads, posting on social media, or relying on word-of-mouth recommendations.",
            "However, these methods often lead to frustration and disappointment due to the lack of suitable options, communication issues, or incompatible lifestyles.",
            "Gone are the days of relying on outdated classifieds or dubious social media posts.",
            "The arduous process of finding flatmates & roommates often involves sifting through countless posts, filled with all kinds of spam, enduring awkward interviews, and crossing your fingers for compatibility.",
            "But fear not – there's a better way.",
        ],
        mainClasses: 'col-12',
    },
    {
        header: 'Enter Flatshare: Your Gateway to Better Life',
        subHeading: [
            'Flatshare revolutionizes the way you find roomates & flatmates.',
            "With our user-friendly app, you can:",
        ],
        numberOfTile: 6,
        mainClasses: 'col-12',
        tiles: [
            {
                img: images.Blog2_1,
                title: 'Tailored Profiles',
                body: 'Craft a detailed profile that reflects your personality, preferences, and lifestyle choices. Think of it as your digital calling card, attracting like-minded individuals seeking the same living experience.'
            },
            {
                img: images.Blog2_2,
                title: 'Smart Matching Algorithm',
                body: 'Say goodbye to serendipity and hello to precision. Our advanced algorithm analyzes user profiles with surgical precision, delivering curated matches based on compatibility factors such as interests, languages, lifestyle, and values.'
            },
            {
                img: images.Blog2_3,
                title: 'Verified Profiles',
                body: 'Enjoy peace of mind knowing you are chatting with verified members, eliminating the risks of scams & fraud.',
            },
            {
                img: images.Blog2_4,
                title: 'Secure Messaging',
                body: 'Communicate with potential flatmates without making your number public, ensuring your privacy and safety.'
            },
            {
                img: images.Blog2_5,
                title: 'Flatscore',
                body: ' Benefit from a community-powered rating system that helps you identify trustworthy and compatible flatmates.'
            }
        ]
    },
    {
        header: 'The Flatshare Advantage',
        numberOfTile: 4,
        mainClasses: 'col-12',
        tiles: [
            {
                img: images.Blog2_6,
                title: 'Save Time and Efforts',
                body: 'Say goodbye to endless online searches, fake profiles & incompatible flatmates. Flatshare streamlines the process, allowing you to find compatible flatmates efficiently.'
            },
            {
                img: images.Blog2_7,
                title: 'Build Community',
                body: 'Forge meaningful connections and friendships with your flatmates, turning your shared living space into a supportive community.'
            },
            {
                img: images.Blog2_8,
                title: 'Peace of Mind Guaranteed',
                body: "With verified profiles and secure messaging, you can trust that you're connecting with genuine, reliable individuals."
            }
        ]
    },
    {
        header: 'Start Your Flatmate Search Today!',
        content: [
            "Don't let the stress of finding flatmates dampen your excitement for your next living adventure. With Flatshare, you can discover the perfect flatmates who share your values, interests, and lifestyle preferences.",
            "Download Flatshare now and embark on a seamless flatmate-finding journey!",
            "Remember, a happy home starts with finding the right flatmates. Let Flatshare be your guide!"
        ],
        mainClasses: 'col-md-7 col-12 end-content',
        buttons: [{
            img: images.playstore,
            link: 'https://joinfs.app/s/s5'
        },
        {
            img: images.appstore,
            link: 'https://joinfs.app/s/s5'
        }
        ]
    }
];

export const relatedBlog = [
    {
        id: 1,
        img: images.bb2,
        title: 'How To Find Flatmates & Roommates For Rent Near Me',
        link: '/how-to-find-flatmates-and-roommates-for-rent-near-me',
        comp: <Blog2 />
    },
    {
        id: 2,
        img: images.bb1,
        title: 'Top Cities to Live in India',
        link: '/top-cities-to-live-in-india',
        comp: <Blog1 />
    }
]