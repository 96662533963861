import SEO from './SEO';
const Privacy = () => {
    return (
        <>
        <SEO title='Privacy Policy | FlatShare' />
            <section className="container-fluid page-container">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h4 className="page-header">Last updated 12th of July, 2021</h4>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Introduction</h5>
                            <p>This Privacy Policy describes the privacy practices of the “Flatshare” that Flatshare Internet Private Limited offers as a mobile application (the “App”).</p>
                            <p>This policy describes:</p>
                            <ul>
                                <li>The types of information we may collect or that you may provide when you download, install, register with, access, or use the Flatshare application (the "App").</li>
                                <li>Our practices for collecting, using, maintaining, protecting, and disclosing that information.</li>
                            </ul>
                            <p>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not download, register with, or use this App.</p>
                            <p>By downloading, registering with, or using this App, you agree to this privacy policy. This policy may change from time to time. Your continued use of this App after we revise this policy means you accept those changes, so please check the policy periodically for updates.</p>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Information We Collect and How We Collect It</h5>
                            <p>We collect information from and about users of our App:</p>
                            <ul>
                                <li>Directly from you when you provide it to us.</li>
                                <li>Automatically when you use the App.</li>
                            </ul>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Information You Provide to Us</h5>
                            <p>When you download, register with, or use this App, we may ask you to provide information by which you may be personally identified, such as name, address, mobile number, or any other identifier by which you may be contacted online or offline ("Personal Information").</p>
                            <p>This information includes:</p>
                            <ul>
                                <li>Information provided at the time of registering to use the App, subscribing to our service, and posting material, and requesting further services.</li>
                                <li>Records and copies of your correspondence if you contact us.</li>
                            </ul>
                            <p>You may also provide information for publication or display ("Your Card") which are transmitted to others at your own risk. Additionally, we cannot control the actions of third parties with whom you may choose to share your info. Therefore, we cannot and do not guarantee that your card will not be viewed by unauthorized persons.</p>
                            <ul>
                                <li>Your contacts’ phone numbers (if you choose to authorize this)</li>
                                <li>Names of flatmates/friends you choose to invite to join you on the App.</li>
                                <li>Payment and transactional data including name, payment card information, bank account number, billing information, and your transaction history. This information is collected by our payment provider, Razorpay, and Flatshare does not have access to payment card numbers. In addition to this Privacy Policy and our Terms of Service, information related to your payments or purchases is also processed according to Razorpay’s services agreement and privacy policy.</li>
                            </ul>
                        </div>
                        <div className="col-12 page-section">
                            <h5>How We Use Your Information</h5>
                            <p>We use information that we collect about you or that you provide to us, including any personal information, to:</p>
                            <ul>
                                <li>Provide you with the App and its contents, and any other information, products, or services that you request from us.</li>
                                <li>Fulfill any other purpose for which you provide it.</li>
                                <li>Give you notices about your account.</li>
                                <li>Carry out our obligations and enforce our rights arising from any contracts entered into between you and us.</li>
                            </ul>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Disclosure of Your Information</h5>
                            <p>We may disclose aggregated information about our users, and information that does not identify any individual or device, without restriction.</p>
                            <p>In addition, we may disclose personal information that we collect or you provide:</p>
                            <ul>
                                <li>Service providers. Companies and individuals that provide services on our behalf or help us operate the App or our business (such as hosting, analytics, customer support, SMS delivery)..</li>
                                <li>To fulfill the purpose for which you provide it.</li>
                                <li>For any other purpose disclosed by us when you provide the information. With your consent.</li>
                                <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
                                <li>To enforce our rights arising from any contracts entered into between you and us.</li>
                                <li>Acquirers and other relevant participants in business transactions (or negotiations for such transactions) involving a corporate divestiture, merger, consolidation, acquisition, reorganization, sale, or other disposition of all or any portion of the business or assets of, or equity interests in, Flatshare Internet Private Limited or our affiliates (including, in connection with a bankruptcy or similar proceedings).</li>
                            </ul>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Accessing and Correcting Your Personal Information</h5>
                            <p>You can review and change your personal information by logging into the App and visiting your account profile page.</p>
                            <p>We cannot delete your personal information except by also deleting your user account. Please note that we will need to verify that you have the authority to delete the account and certain activity generated prior to deletion may remain stored by us and may be shared with third parties as detailed in this Privacy Policy.</p>
                            <p>If you delete your info from the App, copies of your info may remain viewable in cached and archived pages or might have been copied or stored by other App users. Proper access and use of information provided on the App, including User Info, is governed by our terms of use.</p>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Data Security</h5>
                            <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls. Any personal information will be encrypted using SSL technology.</p>
                            <p>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our App, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information in public areas of the App. The information you share in public areas may be viewed by other users of the App.</p>
                            <p>Unfortunately, the transmission of information via the internet and mobile platforms is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through our App. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures we provide.</p>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Changes to Our Privacy Policy</h5>
                            <p>We may update our privacy policy from time to time. If we make material changes to how we treat our users' personal information, we will post the new privacy policy on this page with a notice that the privacy policy has been updated.</p>
                            <p>The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have up-to-date active and deliverable contact information for you and for periodically visiting this privacy policy to check for any changes.</p>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Children</h5>
                            <p>The App is not intended for use by children under 15 years of age. If we learn that we have collected personal information through the App from a child under 15 without the consent of the child’s parent or guardian as required by law, we will delete it.</p>
                        </div>
                        <div className="col-12 page-section">
                            <h5>Contact Information</h5>
                            <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at: <a href="mailto:feedback@flatshare.club">feedback@flatshare.club</a></p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Privacy;