import React, { useEffect } from 'react';
import MapImage from '../../assets/map.png';
import BuildingIcon from '../../assets/building-icon.png';
import DashedLine from '../../assets/building-lines.png';
import Mob1 from '../../assets/mobile1.png';
import Mob2 from '../../assets/mobile2.png';
import Mob3 from '../../assets/mobile3.png';
import './Map.css';
const Map = () => {

    useEffect(() => {
        let slideIndex = 0;
        const showSlides = () => {
            let i;
            let slides = document.getElementsByClassName("slide-images-img");
            let dots = document.getElementsByClassName("status-fill");
            let lifestyle = document.getElementsByClassName("lifestyle-slides");
            if (slides.length === 0) {
                return false;
            }
            for (i = 0; i < slides.length; i++) {
                slides[i].className = slides[i].className.replace(" slide-images-img-active", "");
                lifestyle[i].className = lifestyle[i].className.replace(" lifestyle-slides-active", "");
            }
            slideIndex++;
            if (slideIndex > slides.length) { slideIndex = 1 }
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(" status-filled", "");
            }
            slides[slideIndex - 1].className += " slide-images-img-active";
            dots[slideIndex - 1].className += " status-filled";
            lifestyle[slideIndex - 1].className += " lifestyle-slides-active";
            setTimeout(showSlides, 3000); // Change image every 3 seconds
        }

        showSlides();
    }, []);

    return (
        <section className="container-fluid mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className='row'>
                            <div className="col-12 mb-3">
                                <h2 className='maparea-header section-header-font'>Flatmates Across <span>India</span></h2>
                            </div>
                            <div className='col-lg-9 map-section'>
                                <div className='map-section-container'>
                                    <img src={MapImage} id="map-section-map" alt='' />
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-6 col-12'>
                                        <div className='that-match-your-lifstyle'>
                                            <h4>That match your</h4>
                                            <span className='lifestyle-slides'>Lifestyle</span>
                                            <span className='lifestyle-slides'>Interests</span>
                                            <span className='lifestyle-slides'>Languages</span>
                                            <div className='building-icon'>
                                                <img src={BuildingIcon} alt='' />
                                            </div>
                                            <img src={DashedLine} className='dashed-line' />
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-6 col-12'>
                                        <div className='status-slideshow-box'>
                                            <div className='status-bar'>
                                                <div className='status'>
                                                    <div className='status-fill'></div>
                                                </div>
                                                <div className='status'>
                                                    <div className='status-fill'></div>
                                                </div>
                                                <div className='status'>
                                                    <div className='status-fill'></div>
                                                </div>
                                            </div>
                                            <div className='slide-images'>
                                                {[Mob1, Mob2, Mob3].map((img, key) => (<img kry={key} src={img} className='slide-images-img' />))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Map;