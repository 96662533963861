import bangcol1 from '../../assets/bang-col-1.jpg';
import bangcol2 from '../../assets/bang-col-2.jpg';
import bangcol3 from '../../assets/bang-col-3.jpg';
import bangcol4 from '../../assets/a5dc67db8656bf573e8a7e64dcbbffc7.png';

import mumcol1 from '../../assets/mum-col-1.jpg';
import mumcol2 from '../../assets/mum-col-2.jpg';
import mumcol3 from '../../assets/mum-col-3.jpg';

import delcol1 from '../../assets/del-col-1.jpg';
import delcol2 from '../../assets/del-col-2.jpg';
import delcol3 from '../../assets/del-col-3.png';

import punecol1 from '../../assets/pune-col-1.jpg';
import punecol2 from '../../assets/pune-col-2.jpg';
import punecol3 from '../../assets/pune-col-3.jpg';

import hycol1 from '../../assets/hy-col-1.png';
import hycol2 from '../../assets/hy-col-2.jpg';
import hycol3 from '../../assets/hy-col-3.jpg';

import chcol1 from '../../assets/ch-col-1.jpg';
import chcol2 from '../../assets/ch-col-2.jpg';
import chcol3 from '../../assets/ch-col-3.jpg';

import kolcol1 from '../../assets/kol-col-1.jpg';
import kolcol2 from '../../assets/kol-col-2.jpg';
import kolcol3 from '../../assets/kol-col-3.jpg';

import amcol1 from '../../assets/am-col-1.jpg';
import amcol2 from '../../assets/am-col-2.jpg';
import amcol3 from '../../assets/am-col-3.png';


import bangat1 from '../../assets/bang-at-1.jpg';
import bangat2 from '../../assets/bang-at-2.jpg';
import bangat3 from '../../assets/bang-at-3.jpg';
import bangat4 from '../../assets/f7039f9e659b2b9212e664a35f16914e.jpg';

import mumat1 from '../../assets/mum-at-1.jpg';
import mumat2 from '../../assets/mum-at-2.jpg';
import mumat3 from '../../assets/mum-at-3.jpg';

import delat1 from '../../assets/del-at-1.jpg';
import delat2 from '../../assets/del-at-2.jpg';
import delat3 from '../../assets/del-at-3.jpg';

import puneat1 from '../../assets/pune-at-1.jpg';
import puneat2 from '../../assets/pune-at-2.jpg';
import puneat3 from '../../assets/pune-at-3.jpg';

import hyat1 from '../../assets/hy-at-1.png';
import hyat2 from '../../assets/hy-at-2.jpg';
import hyat3 from '../../assets/hy-at-3.jpg';

import chat1 from '../../assets/ch-at-1.jpg';
import chat2 from '../../assets/ch-at-2.jpg';
import chat3 from '../../assets/ch-at-3.jpg';

import kolat1 from '../../assets/kol-at-1.jpg';
import kolat2 from '../../assets/kol-at-2.jpg';
import kolat3 from '../../assets/kol-at-3.jpg';

import amat1 from '../../assets/am-at-1.jpg';
import amat2 from '../../assets/am-at-2.jpg';
import amat3 from '../../assets/am-at-3.jpg';

import ico1 from '../../assets/graduation.svg';
import ico2 from '../../assets/briefcase.svg';
import ico3 from '../../assets/building-house.svg';
import ico4 from '../../assets/happy-beaming.svg';
import ico5 from '../../assets/cloud.svg';
import ico6 from '../../assets/heart-circle.svg';
import ico7 from '../../assets/line-chart.svg';
import ico8 from '../../assets/like.svg';

import Blog2_1 from '../../assets/blog2-1.png';
import Blog2_2 from '../../assets/blog2-2.png';
import Blog2_3 from '../../assets/blog2-3.png';
import Blog2_4 from '../../assets/blog2-4.png';
import Blog2_5 from '../../assets/blog2-5.png';
import Blog2_6 from '../../assets/blog2-6.png';
import Blog2_7 from '../../assets/blog2-7.png';
import Blog2_8 from '../../assets/blog2-8.png';

import b2_banner_1 from '../../assets/260.png';
import b2_banner_2 from '../../assets/261.png';
import b2_banner_3 from '../../assets/262.png';
import b2_banner_4 from '../../assets/101.svg';
import b2_banner_5 from '../../assets/rayL.svg';
import b2_banner_6 from '../../assets/rayR.svg';
import b2_banner_7 from '../../assets/65666854.svg';
import b2_banner_8 from '../../assets/path.svg';

import playstore from '../../assets/playstore.png';
import appstore from '../../assets/appstore.png';

import bb1 from '../../assets/blog1-banner.jpg';
import bb2 from '../../assets/blog2-banner.png';


import newim1 from '../../assets/4e08add76b69391b156e080c08d50272.jpg';
import newim2 from '../../assets/0b182dfae15900d6649a1f680534de66.jpg';
import newim3 from '../../assets/b9a00f93f2c8f54153a1c61af8b97343.jpg';
import newim4 from '../../assets/9fa5130dececf824740d35f3e256483e.jpg';
import a from '../../assets/918088e314121a8e65ddf3fbf350beb0.jpg';
import b from '../../assets/d5a2f516ab86f3d2d164e93ebc8b7b62.jpg';
import c from '../../assets/3fe93251d4c2c3a8fdcd9fc0855548f7.png';
import d from '../../assets/d2cf8101cb38da9db268a8e9f6a9304f.jpg';
import e from '../../assets/a2be263d8f3f6e00826bddfb69a5dcec.jpg';
import f from '../../assets/f6203bece9d07293cbb6772b0af74cdc.jpg';
import g from '../../assets/d953f52bdcb1c7c95f0dcb1aad2752fd.jpg';
import h from '../../assets/ba002fef40ad7c1b053abd3358aeed59.jpg';
import i from '../../assets/f645844cad9522609e7e95ac0ed168d5.jpg';
import j from '../../assets/c7960081b9737f4eb2e094add20d042d.jpg';
import k from '../../assets/33ef99c3b501417444f9f7704fba0260.jpg';
import l from '../../assets/0982b4dff64a0e34cc434c10887050b1.jpg';
import m from '../../assets/1f3e314d63c3c84f7930c836334b161f.jpg';
import n from '../../assets/6acea8863c660d38cc4d8468239c08b3.png';
import o from '../../assets/7fa9675928f1a711cc9a84db6720175f.jpg';
import p from '../../assets/c9482661dfb06fec7fdf837b457c2e5b.jpg';
import q from '../../assets/3b5f88807ee3f1040bd6ef271e38970d.jpg';
import r from '../../assets/f0f7d309f49e6679a62d0279bded2332.jpg';
import s from '../../assets/ae44c35525c3f969156d65b94a24ff32.jpg';
import t from '../../assets/68f61c13922ace44c123e7c2607cc806.png';
import u from '../../assets/77150becadf7f96aaa41947b015df52b.png';
import v from '../../assets/0aa03c8ebf1b6c4ede6bf30778604a2d.png';
import w from '../../assets/edb2ae1bf35c38126e7362ec08472519.jpg';
import x from '../../assets/d7a82abcc4429b89218174973a5948ed.jpg';
import y from '../../assets/a7347ad5ad5af290480b108169d4eb32.jpg';

export const images = {
    a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y,
    bangcol4, bangat4, newim1, newim2, newim3, newim4,
    playstore, appstore, bb1, bb2,
    b2_banner_1, b2_banner_2, b2_banner_3, b2_banner_4, b2_banner_5, b2_banner_6, b2_banner_7, b2_banner_8,
    Blog2_1, Blog2_2, Blog2_3, Blog2_4, Blog2_5, Blog2_6, Blog2_7, Blog2_8,
    ico1, ico2, ico3, ico4, ico5, ico6, ico7, ico8,
    amat1, amat2, amat3, amcol1, amcol2, amcol3,
    kolat1, kolat2, kolat3, kolcol1, kolcol2, kolcol3,
    mumat1, mumat2, mumat3, mumcol1, mumcol2, mumcol3,
    bangat1, bangat2, bangat3, bangcol1, bangcol2, bangcol3,
    delat1, delat2, delat3, delcol1, delcol2, delcol3,
    chat1, chat2, chat3, chcol1, chcol2, chcol3,
    puneat1, puneat2, puneat3, punecol1, punecol2, punecol3,
    hyat1, hyat2, hyat3, hycol1, hycol2, hycol3
}