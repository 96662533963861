import footerLogo from '../../assets/LogoF.svg';
import { Link } from "react-router-dom";
import './Footer.css';
const Footer = () => {
    return (
        <footer className="container-fluid">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <Link to="/"><img src={footerLogo} className='footer-logo' alt=''/><h6 className='copyright'> © Flatshare Internet Private Limited</h6></Link>
                        
                    </div>
                    <div className="col-md-6 text-md-end text-start">
                        <ul className="footer-links">
                            <li><Link to="/terms-and-condition">Terms of Use</Link></li>
                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer;